// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "K_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "K_fR d_fR d_bz d_bJ";
export var menuDesign6 = "K_ww d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "K_wx d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "K_wy d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "K_wz d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "K_wB d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "K_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "K_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "K_wC";
export var navbarItem = "K_nb d_bx";
export var navbarLogoItemWrapper = "K_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "K_wD d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "K_wF d_gd d_by d_Z d_bs";
export var burgerToggle = "K_wG d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "K_wH d_gd d_by d_Z d_bs";
export var burgerInput = "K_wJ d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "K_wK d_f3 d_w d_H";
export var burgerLine = "K_wL d_f1";
export var burgerMenuDesign6 = "K_wM d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "K_wN d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "K_wP d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "K_wQ d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "K_wR d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "K_wS d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "K_wT d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "K_wV d_bC d_bP";
export var compact = "K_wW";
export var navDivided = "K_wX";
export var staticBurger = "K_wY";
export var menu = "K_wZ";
export var navbarDividedLogo = "K_w0";
export var nav = "K_w1";
export var fixed = "K_w2";