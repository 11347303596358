// extracted by mini-css-extract-plugin
export var alignDiscLeft = "y_t1 d_fp d_bG d_dv";
export var alignLeft = "y_rd d_fp d_bG d_dv";
export var alignDiscCenter = "y_t2 d_fq d_bD d_dw";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignDiscRight = "y_t3 d_fr d_bH d_dx";
export var alignRight = "y_rf d_fr d_bH d_dx";
export var footerContainer = "y_t4 d_dW d_bW";
export var footerContainerFull = "y_t5 d_dT d_bW";
export var footerHeader = "y_kf d_kf";
export var footerTextWrapper = "y_t6 d_w";
export var footerDisclaimerWrapper = "y_km d_km d_ck";
export var badgeWrapper = "y_t7 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "y_t8 d_bz d_bJ d_bN d_bL";
export var wide = "y_t9 d_cy";
export var right = "y_vb d_bK";
export var line = "y_fk d_fl d_cv";
export var badgeDisclaimer = "y_vc d_bC d_bP d_bJ";
export var badgeContainer = "y_vd d_bz d_bH d_bP";
export var badge = "y_vf";
export var padding = "y_vg d_c7";
export var end = "y_vh d_bH";
export var linkWrapper = "y_vj d_dB";
export var link = "y_mC d_dB";
export var colWrapper = "y_vk d_cx";
export var consent = "y_f d_f d_bC d_bP";
export var disclaimer = "y_vl d_bz d_bJ";
export var media = "y_vm d_bx d_dy";
export var itemRight = "y_vn";
export var itemLeft = "y_vp";
export var itemCenter = "y_vq";
export var exceptionWeight = "y_vr w_s6";