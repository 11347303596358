// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_vs d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_vt d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_vv d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_vw d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_vx d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_vy d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_vz d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_ty w_ty";
export var heroExceptionNormal = "z_tz w_tz";
export var heroExceptionLarge = "z_tB w_tB";
export var Title1Small = "z_s9 w_s9 w_sG w_sH";
export var Title1Normal = "z_tb w_tb w_sG w_sJ";
export var Title1Large = "z_tc w_tc w_sG w_sK";
export var BodySmall = "z_tp w_tp w_sG w_sZ";
export var BodyNormal = "z_tq w_tq w_sG w_s0";
export var BodyLarge = "z_tr w_tr w_sG w_s1";