// extracted by mini-css-extract-plugin
export var carouselContainer = "M_w4 d_w d_H d_bf d_Z";
export var carouselContainerCards = "M_w5 M_w4 d_w d_H d_bf d_Z";
export var carouselContainerSides = "M_w6 d_w d_H d_Z";
export var prevCarouselItem = "M_w7 d_w d_H d_0 d_k";
export var prevCarouselItemL = "M_w8 M_w7 d_w d_H d_0 d_k";
export var moveInFromLeft = "M_w9";
export var prevCarouselItemR = "M_xb M_w7 d_w d_H d_0 d_k";
export var moveInFromRight = "M_xc";
export var selectedCarouselItem = "M_xd d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "M_xf M_xd d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "M_xg M_xd d_w d_H d_Z d_bf";
export var nextCarouselItem = "M_xh d_w d_H d_0 d_k";
export var nextCarouselItemL = "M_xj M_xh d_w d_H d_0 d_k";
export var nextCarouselItemR = "M_xk M_xh d_w d_H d_0 d_k";
export var arrowContainer = "M_xl d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "M_xm M_xl d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "M_xn M_xm M_xl d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "M_xp M_xl d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "M_xq";
export var nextArrowContainerHidden = "M_xr M_xp M_xl d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "M_kG d_0";
export var prevArrow = "M_xs M_kG d_0";
export var nextArrow = "M_xt M_kG d_0";
export var carouselIndicatorContainer = "M_xv d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "M_xw d_w d_bz d_bF";
export var carouselText = "M_xx d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "M_xy M_xx d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "M_xz d_b7";
export var carouselIndicator = "M_xB M_xz d_b7";
export var carouselIndicatorSelected = "M_xC M_xz d_b7";
export var arrowsContainerTopRight = "M_xD d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "M_xF d_0 d_bl d_bC";
export var arrowsContainerSides = "M_xG d_0 d_bl d_bC";
export var smallArrowsBase = "M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "M_xJ M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "M_xK M_xJ M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "M_xL M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "M_xM M_xL M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "M_n9";
export var iconSmall = "M_xN";
export var multipleWrapper = "M_xP d_bC d_bF d_bf";
export var multipleImage = "M_xQ d_bC";
export var sidesPrevContainer = "M_xR M_xJ M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "M_xS M_xJ M_xH d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";