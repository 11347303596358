// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "D_wb d_gS d_cw d_dv";
export var quoteParagraphCenter = "D_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "D_gV d_gV d_cw d_dx";
export var quoteRowLeft = "D_wc d_bG";
export var quoteRowCenter = "D_wd d_bD";
export var quoteRowRight = "D_wf d_bH";
export var quoteWrapper = "D_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "D_gR d_gR";
export var quoteExceptionSmall = "D_tG w_tG";
export var quoteExceptionNormal = "D_tH w_tH";
export var quoteExceptionLarge = "D_tJ w_tJ";
export var quoteAuthorExceptionSmall = "D_tK w_tK";
export var quoteAuthorExceptionNormal = "D_tL w_tL";
export var quoteAuthorExceptionLarge = "D_tM w_tM";